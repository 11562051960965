var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', [_c('v-list-item-group', {
    attrs: {
      "mandatory": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/profile"
    }
  }, [_c('v-list-item-title', [_vm._v("회원정보수정")])], 1), _c('v-list-item', {
    attrs: {
      "dense": "",
      "to": "/member/gshop/orders"
    }
  }, [_c('v-list-item-title', [_vm._v("상품권 구매내역")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }