<template>
    <v-list>
        <v-list-item-group mandatory>
            <v-list-item dense to="/member/profile">
                <v-list-item-title>회원정보수정</v-list-item-title>
            </v-list-item>
            <v-list-item dense to="/member/gshop/orders">
                <v-list-item-title>상품권 구매내역</v-list-item-title>
            </v-list-item>

        </v-list-item-group>
    </v-list>

</template>

<script>
export default {
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        scope(){
            return this.$store.state.scope;
        },
        logoff(){
            return !this.$store.state.accessToken;
        },
        siteInfo(){
            return this.$store.state.siteInfo;
        }
    },
    mounted(){
        this.$store.dispatch("getSiteInfo");
    }
}
</script>

<style scoped>
.v-list-item__title {
    font-size: 16px;
}
</style>