var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    staticClass: "fill-height justify-center align-start background-content"
  }, [_c('v-responsive', {
    staticClass: "py-2 py-lg-16",
    class: {
      'px-4': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "width": "100%",
      "max-width": "1200"
    }
  }, [_c('v-layout', [!_vm.$vuetify.breakpoint.mobile ? _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-card', {
    staticClass: "white--text",
    attrs: {
      "width": "260",
      "flat": "",
      "color": "primary",
      "elevation": "0",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('span', {
    staticStyle: {
      "font-size": "22px"
    }
  }, [_vm._v("마이페이지")])]), _c('mypage-list')], 1)], 1) : _vm._e(), _c('v-flex', {
    class: {
      'pl-4': !_vm.$vuetify.breakpoint.mobile
    },
    staticStyle: {
      "flex-grow": "1 !important"
    },
    attrs: {
      "shrink": ""
    }
  }, [_vm._t("default")], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }